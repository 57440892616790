<template>
  <BCard>
    <div class="d-flex align-items-center mb-2">
      <div
        class="wrapper-back p-50 mr-1"
        @click="goBack"
      >
        <BImg src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/arrow-left-2.svg" />
      </div>
      <span class="text-black text-[24px]">
        <strong>
          Bantuan
        </strong>
      </span>
    </div>

    <div class="mb-1">
      <span class="text-black text-[16px]">
        Bagaimana kami bisa membantu Anda?
      </span>
    </div>

    <BRow class="pl-1">
      <BCol
        v-for="(item, index) in items.filter(item => $route.params.data.order_status === item.orderStatus)"
        :key="index"
        cols="auto"
        class="d-flex align-items-start justify-content-between wrapper-list-helper p-1 mb-1 mr-2"
        @click="actionReport(item)"
      >
        <div class="d-flex flex-column">
          <span class="text-black text-[16px]">
            <strong>
              {{ item.title }}
            </strong>
          </span>
          <div class="mt-1">
            <small>
              {{ item.subtitle }}
            </small>
          </div>
        </div>
        <div>
          <BImg
            width="28"
            src="https://storage.googleapis.com/komerce/assets/illustration/arrow-right.svg"
          />
        </div>
      </BCol>
    </BRow>

    <!-- Modal Received Problem -->
    <BModal
      ref="modal-action-report"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      @hidden="onCloseModalActionReport"
    >

      <BRow class="my-3">
        <BCol
          cols="12"
          class="mb-1 text-center"
        >
          <span class="text-black text-[24px]">
            <strong>
              Bukti Unboxing Paket
            </strong>
          </span>
        </BCol>

        <BCol
          cols="12"
          class="text-center mb-1"
        >
          <span class="text-black">
            Untuk proses claim Paket Bermasalah seperti Rusak / Tidak Berfungsi karena pengiriman, mohon upload bukti Video dan Foto saat Unboxing Paket dengan resi yang tertera.
          </span>
        </BCol>

        <BCol cols="12">
          <div class="form-group mb-2">
            <label
              for="video"
              class="text-black"
            >Upload Video<span class="text-primary">*</span></label>
            <div class="input-group">
              <input
                id="video"
                v-model="url"
                type="text"
                class="form-control input-proof"
                placeholder="Masukkan link video"
                @input="validationUrl"
              >
              <div class="input-group-append">
                <span
                  class="input-group-text input-proof"
                >Link</span>
              </div>
            </div>
            <small
              v-if="urlError === 'invalid'"
              class="text-primary"
            >*URL tidak valid</small>
            <small
              class="form-text text-xs"
            >Link yang dikirimkan berisikan video dan pastikan dapat dibaca oleh publik</small>
          </div>

          <div class="mb-2">
            <div>
              <small class="text-black">Upload Foto<small class="text-primary">*</small></small>
            </div>
            <label
              for="files-proof"
              class="cursor-pointer w-full"
              :style="
                fileList.length < 3 ? 'cursor: pointer!important;' : 'cursor: not-allowed!important;'
              "
            >
              <div class="input-group justify-content-between w-full">
                <div class="wrapper-choose-file pl-1 pt-50">
                  <span class="text-[14px] text-muted">
                    Pilih File
                  </span>
                </div>
                <div class="input-group-append">
                  <span
                    class="input-group-text input-proof"
                  >File</span>
                </div>
              </div>
            </label>
            <small
              v-if="fileSizeExceeded"
              class="text-primary"
            >*Ukuran file maksimal 2mb</small>
            <input
              id="files-proof"
              type="file"
              multiple
              hidden
              accept="image/x-png,image/jpg,image/jpeg"
              :disabled="fileList.length < 3 ? false : true"
              @input="handleFileInput($event)"
            >
          </div>

          <!-- Preview File -->
          <div class="d-flex flex-wrap">
            <div
              v-for="(file, index) in fileList"
              :key="index"
              class="wrapper-preview-file mr-2 mb-2"
            >
              <b-img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/close-circle.svg"
                class="close-img-preview"
                @click="removeFile(index)"
              />
              <b-img
                :src="file.url"
                class="img-preview"
                @click="openPreview(file.url)"
              />
              <b-spinner
                v-if="file.spinner"
                class="custom-spinner-preview"
                variant="light"
              />
              <div>
                <span>
                  ...{{ nameFiles[index].slice(-9) }}
                </span>
              </div>
            </div>
          </div>

          <div class="d-flex mt-2">
            <b-button
              variant="outline-primary"
              class="mr-1 mt-0 btn-modal-report"
              block
              @click="hideModalReport('received-problem')"
            >
              Batal
            </b-button>
            <b-button
              variant="primary"
              :class="validationClaim() ? 'btn-modal-report-disabled mt-0' : 'btn-modal-report mt-0'"
              block
              :disabled="validationClaim()"
              @click="submitClaim"
            >
              Ajukan Claim Rusak
              <b-spinner
                v-if="loadingClaims"
                small
                class="ml-50"
              />
            </b-button>
          </div>
        </BCol>
      </BRow>

    </BModal>

    <!-- Modal Not Yet Received -->
    <BModal
      ref="modal-notyet-received"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >

      <BRow class="my-3">
        <BCol
          cols="12"
          class="mb-1 text-center"
        >
          <span class="text-black text-[24px]">
            <strong>
              Informasi Pengiriman
            </strong>
          </span>
        </BCol>

        <BCol
          v-if="imagePod"
          cols="12"
          class="text-center mb-1"
        >
          <span class="text-black">
            Paket ini memiliki bukti pengiriman, mohon periksa ulang apakah pesanan sudah sampai.
          </span>
        </BCol>
        <BCol
          v-else
          cols="12"
          class="text-center mb-1"
        >
          <span class="text-black">
            Paket ini tidak memiliki bukti pengiriman / belum berhasil mengambil file bukti pengiriman
          </span>
        </BCol>

        <BCol
          v-if="imagePod"
          cols="12"
          class="mb-1"
        >
          <BImg
            :src="imagePod.proof_url_image"
            class="image-pod"
          />
        </BCol>

        <BCol
          cols="12"
          class="mb-1"
        >
          <div class="d-flex align-items-center">
            <div class="wrapper-alert-information d-flex align-items-center p-1 mr-1">
              <BImg
                src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                class="mr-1"
              />
              <span class="text-xs">
                Kamu dapat mengirimkan bukti / konfirmasi kembali ke Pembeli.
              </span>
            </div>

            <BButton
              :variant="!imagePod ? 'secondary' : 'primary'"
              class="wrapper-btn-download p-1"
              :style="imagePod ? 'cursor: pointer' : 'cursor: not-allowed'"
              :disabled="!imagePod"
              @click="downloadImagePod"
            >
              <BImg src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/document-download.svg" />
            </BButton>
          </div>
        </BCol>

        <BCol
          cols="12"
        >
          <div>
            <span class="text-[12px]">
              Resi : {{ $route.params.data.airway_bill }}
            </span>
          </div>
          <div>
            <span class="text-[12px]">
              Telah Terkirim pada : {{ DASH_DATE($route.params.data.updated_at) }}
            </span>
          </div>
        </BCol>

        <BCol cols="12">
          <div class="d-flex mt-2">
            <b-button
              variant="outline-primary"
              class="mr-1 mt-0 btn-modal-report"
              block
              @click="hideModalReport('notyet-received')"
            >
              Batal
            </b-button>
            <b-button
              variant="primary"
              class="btn-modal-report mt-0"
              block
              @click="nextClaim"
            >
              Lanjut Ajukan Claim
            </b-button>
          </div>
        </BCol>
      </BRow>

    </BModal>

    <!-- Modal Next Not Yet Received -->
    <BModal
      ref="modal-notyet-received-next"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      @hidden="onCloseModalActionReport"
    >

      <BRow class="my-3">
        <BCol
          cols="12"
          class="mb-1 text-center"
        >
          <span class="text-black text-[24px]">
            <strong>
              Bukti Tidak Menerima Paket
            </strong>
          </span>
        </BCol>

        <BCol
          cols="12"
          class="text-center mb-1"
        >
          <span class="text-black">
            Untuk proses claim Paket Tidak Diterima, kamu bisa upload bukti foto berupa chat Pembeli tidak menerima dan Foto Bangunan pembeli sebagai bahan identifikasi Ekspedisi.
          </span>
        </BCol>

        <BCol cols="12">
          <div class="mb-2">
            <div>
              <small>Upload Foto (Opsional)</small>
            </div>
            <label
              for="files-proof"
              class="cursor-pointer w-full"
              :style="
                fileList.length < 3 ? 'cursor: pointer!important;' : 'cursor: not-allowed!important;'
              "
            >
              <div class="input-group justify-content-between w-full">
                <div class="wrapper-choose-file pl-1 pt-50">
                  <span class="text-[14px] text-muted">
                    Pilih File
                  </span>
                </div>
                <div class="input-group-append">
                  <span
                    class="input-group-text input-proof"
                  >File</span>
                </div>
              </div>
            </label>
            <small
              v-if="fileSizeExceeded"
              class="text-primary"
            >*Ukuran file maksimal 2mb</small>
            <input
              id="files-proof"
              type="file"
              multiple
              hidden
              accept="image/x-png,image/jpg,image/jpeg"
              :disabled="fileList.length < 3 ? false : true"
              @input="handleFileInput($event)"
            >
          </div>

          <!-- Preview File -->
          <div class="d-flex flex-wrap">
            <div
              v-for="(file, index) in fileList"
              :key="index"
              class="wrapper-preview-file mr-2 mb-2"
            >
              <b-img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/close-circle.svg"
                class="close-img-preview"
                @click="removeFile(index)"
              />
              <b-img
                :src="file.url"
                class="img-preview"
                @click="openPreview(file.url)"
              />
              <b-spinner
                v-if="file.spinner"
                class="custom-spinner-preview"
                variant="light"
              />
              <div>
                <span>
                  ...{{ nameFiles[index].slice(-9) }}
                </span>
              </div>
            </div>
          </div>

          <div class="d-flex mt-2">
            <b-button
              variant="outline-primary"
              class="mr-1 mt-0 btn-modal-report"
              block
              @click="closeClaimNotyetReceived"
            >
              Batalkan
            </b-button>
            <b-button
              variant="primary"
              class="btn-modal-report mt-0"
              block
              @click="submitClaim"
            >
              Ajukan Claim Hilang
              <b-spinner
                v-if="loadingClaims"
                small
                class="ml-50"
              />
            </b-button>
          </div>
        </BCol>
      </BRow>

    </BModal>

    <!-- Modal Preview Img -->
    <BModal
      ref="modal-preview-img"
      centered
      hide-header
      hide-footer
      size="xl"
    >
      <BRow class="justify-content-center my-4">
        <BImg :src="prevImg" />
      </BRow>
    </BModal>

    <!-- Modal Success -->
    <BModal
      ref="modal-success"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <BRow class="justify-content-center text-center my-3">
        <BCol
          cols="12"
          class="d-flex justify-content-center mb-2"
        >
          <BImg
            src="https://storage.googleapis.com/komerce/core/icon-popup-success.png"
            width="100"
          />
        </BCol>
        <BCol
          cols="12"
          class="mb-2"
        >
          <span class="text-black text-[24px]">
            <strong>
              Klaim Berhasil Diajukan
            </strong>
          </span>
        </BCol>
        <BCol
          cols="12"
          class="mb-2"
        >
          <span class="text-black">
            Pengajuan Claim Paket
            {{ claimType === 'tidak_diterima' || claimType === 'rts_tidak_diterima' ? ' Tidak Diterima sudah direkam' : ' Rusak/Tidak Berfungsi karena sebab pengiriman sudah diterima' }}.
            Tim kami akan memverifikasi dalam 5 hari. Kamu dapat memantau perkembangannya di detail order resi yang diajukan.
          </span>
        </BCol>
        <BCol cols="12">
          <BButton
            variant="primary"
            block
            @click="moveDetailOrder"
          >
            Lihat Detail Order
          </BButton>
        </BCol>
      </BRow>
    </BModal>
  </BCard>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import { DASH_DATE } from '@/libs/formatDate'

const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/

export default {
  data() {
    return {
      items: [
        {
          title: 'Status paket saya “Diterima” namun ada masalah',
          subtitle: 'Seperti kemasan paket Rusak sehingga barang juga rusak dan barang tidak berfungsi dengan baik',
          type: 'rusak',
          orderStatus: 'Diterima',
        },
        {
          title: 'Status paket saya “Diterima” namun barang belum diterima pembeli',
          subtitle: 'Seperti paket sudah berstatus "Diterima" namun ternyata belum diterima oleh pembeli',
          type: 'tidak_diterima',
          orderStatus: 'Diterima',
        },
        {
          title: 'Status paket saya “Retur Diterima” namun ada masalah',
          subtitle: 'Seperti kemasan paket Rusak sehingga barang juga rusak dan barang tidak berfungsi dengan baik',
          type: 'rts_rusak',
          orderStatus: 'Retur',
        },
        {
          title: 'Status paket saya “Retur Diterima” namun barang belum diterima penjual',
          subtitle: 'Seperti paket sudah berstatus "Retur Diterima" namun ternyata belum diterima oleh penjual',
          type: 'rts_tidak_diterima',
          orderStatus: 'Retur',
        },
      ],

      prevImg: null,
      fileList: [],
      maxFileSize: 2000000,
      fileSizeExceeded: false,
      urlError: '',
      url: null,
      nameFiles: [],
      imgTemp: [],
      loadingClaims: false,
      claimType: '',
      imagePod: null,
      DASH_DATE,
    }
  },
  mounted() {
    if (this.$route.params.data === undefined) {
      this.goBack()
    }
  },
  methods: {
    actionReport(data) {
      let status = ''
      if (data.orderStatus === 'Diterima') {
        status = 'delivered'
      } else if (data.orderStatus === 'Retur') {
        status = 'retur'
      }
      this.claimType = data.type
      if (data.type === 'tidak_diterima' || data.type === 'rts_tidak_diterima') {
        this.$http_new.get(`shipping/api/v1/awb/proof/${status}/${this.$route.params.data.airway_bill}`).then(response => {
          this.imagePod = response.data.data
        }).catch(() => {
          this.imagePod = null
        })
      }
      return data.type === 'rusak' || data.type === 'rts_rusak' ? this.$refs['modal-action-report'].show() : this.$refs['modal-notyet-received'].show()
    },
    hideModalReport(state) {
      return state === 'received-problem' ? this.$refs['modal-action-report'].hide() : this.$refs['modal-notyet-received'].hide()
    },
    async handleFileInput($event) {
      this.imgTemp = []
      const files = Array.from($event.target.files)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (this.fileList.length < 3) {
          if (
            ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)
          && !file.name.includes('jfif')
          && file.size < this.maxFileSize
          ) {
            this.fileSizeExceeded = false
            // eslint-disable-next-line no-await-in-loop
            await this.processFile(file)
          } else {
            this.fileSizeExceeded = true
          }
        }
      }
    },
    async processFile(file) {
      await this.uploadImg(file)
    },
    async uploadImg(file) {
      const formData = new FormData()
      formData.append('file', file)
      await this.$http_new.post('komship/api/v1/claims/upload-img/temp', formData).then(response => {
        const { data } = response.data
        this.imgTemp.push(data.id)
        this.fileList.push(data)
        this.nameFiles.push(data.url)
      }).catch(err => {
        this.loadingClaims = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: err,
            variant: 'danger',
          },
        }, 2000)
      })
    },
    removeFile(index) {
      this.nameFiles.splice(index, 1)
      this.fileList.splice(index, 1)
    },
    openPreview(file) {
      this.prevImg = file
      this.$refs['modal-preview-img'].show()
    },
    validationUrl() {
      if (this.url !== '') {
        if (!urlPattern.test(this.url)) {
          this.urlError = 'invalid'
        } else {
          this.urlError = ''
        }
      } else {
        this.urlError = ''
      }
    },
    validationClaim() {
      if (this.urlError === 'invalid' || this.fileList.length < 1 || this.fileSizeExceeded || !this.url) {
        return true
      }
      return false
    },
    moveDetailOrder() {
      this.$router.push({ name: 'detail-order', params: { order_id: this.$route.params.id } })
    },
    submitClaim() {
      this.loadingClaims = true
      const params = {
        order_id: Number(this.$route.params.id),
        type: this.claimType,
      }

      if (this.claimType === 'rusak' || this.claimType === 'rts_rusak') Object.assign(params, { video_url: this.url })
      if (this.imgTemp.length > 0) Object.assign(params, { images_id: this.imgTemp })

      this.$http_new.post('komship/api/v1/claims', params).then(() => {
        this.loadingClaims = false
        this.onCloseModalActionReport()
        this.$refs['modal-success'].show()
      }).catch(err => {
        this.loadingClaims = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: 'Gagal Submit Claim',
            variant: 'danger',
          },
        }, 2000)
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    onCloseModalActionReport() {
      this.urlError = ''
      this.fileList = []
      this.url = null
      this.$refs['modal-action-report'].hide()
      this.$refs['modal-notyet-received-next'].hide()
    },
    nextClaim() {
      this.$refs['modal-notyet-received'].hide()
      this.$refs['modal-notyet-received-next'].show()
    },
    closeClaimNotyetReceived() {
      this.fileList = []
      this.$refs['modal-notyet-received-next'].hide()
    },
    downloadImagePod() {
      const linkSource = `data:image/png;base64,${this.imagePod.base_64}`
      const downloadLink = document.createElement('a')
      const fileName = `${this.$route.params.data.shipping}_${this.$route.params.data.airway_bill}_${DASH_DATE(this.$route.params.data.updated_at)}.jpg`

      downloadLink.setAttribute('target', '_blank')
      downloadLink.setAttribute('download', fileName)
      downloadLink.setAttribute('href', linkSource)
      downloadLink.click()
    },
  },
}
</script>

<style scoped>
    .wrapper-back {
        background: #F95031;
        border-radius: 8px;
        cursor: pointer;
    }

    .wrapper-back:hover {
        background: #f87a63;
    }

    .wrapper-list-helper {
        border-radius: 4px;
        border: 1px solid #E2E2E2;
        width: 700px;
    }

    .wrapper-list-helper:hover {
        background: #E2E2E2;
        cursor: pointer;
    }

    .btn-modal-report {
        border-radius: 8px;
    }

    .input-proof {
        border-radius: 8px;
        background: white;
    }

    .wrapper-choose-file {
        height: 35px;
        border-radius: 8px 0px 0px 8px;
        border: 1px solid #E2E2E2;
        width: 88.6%;
    }

    .input-group > .form-control:not(:last-child) {
      color: #08A0F7;
    }

    .wrapper-preview-file {
      position: relative;
    }

    .img-preview {
      border-radius: 12px;
      cursor: pointer;
      width: 60px;
      height: 60px;
    }

    .close-img-preview {
      position: absolute;
      top: -15%;
      right: 5%;
      cursor: pointer;
    }

    .btn-modal-report-disabled {
      border-radius: 8px;
      border: 1px solid #E2E2E2!important;
      background: #E2E2E2!important;
      color: #C2C2C2!important;
      cursor: not-allowed;
    }

    .wrapper-alert-information {
      border-radius: 2px;
      background: #FCEBBE;
    }

    .wrapper-btn-download {
      border-radius: 4px;
      background: #F95031;
    }

    .custom-spinner-preview {
      position: absolute;
      top: 20%;
      left: 20%;
    }

    .image-pod {
      width: 540px;
      height: 424px;
    }
</style>
